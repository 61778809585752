.spinner {
  stroke: var(--primary);
  stroke-width: 0.4;
  fill: transparent;
  animation-name: spinner;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  stroke-dasharray: 32;
}

@keyframes spinner {
  0% {
    stroke-dashoffset: 32;
  }

  50% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -32;
  }
}

.check {
  stroke: #00e676;
  stroke-width: 0.8;
  fill: transparent;
}

.check-animation {
  animation-name: check;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  stroke-dasharray: 12;
  stroke-dashoffset: 12;
}

@keyframes check {
  50% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}

.fade-in-animation {
  opacity: 1;
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

.linear-progress-animation {
  animation-name: linear-progress;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@keyframes linear-progress {
  from {
    width: 0;
  }
}
