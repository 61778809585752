:root {
  --primary: #333;
  --secondary: #333;
  --background: #fff;
  --foreground: #eee;
  --border: #e0e0e0;
  --error: #ff1744;
  --success: #00e676;

  --primary-font: "Roboto Mono", sans-serif;
  --font-size-1: 0.875rem;
  --font-size-2: 1.125rem;
  --font-size-3: 1.5rem;

  --tap-size: 2.5rem;
}

/* ensures full screen fit on mobile devices browser */
html,
body,
div#root {
  height: 100%;
}

body {
  background-color: var(--background);
  color: var(--primary);
  font-family: var(--primary-font);
  font-size: var(--font-size-1);
}

.f1 {
  font-size: var(--font-size-1);
}

.f2 {
  font-size: var(--font-size-2);
}

.f3 {
  font-size: var(--font-size-3);
}

/* color */

.c-primary {
  color: var(--primary);
}

.c-secondary {
  color: var(--secondary);
}

.c-background {
  color: var(--background);
}

.c-error {
  color: var(--error);
}

/* background-color */

.bg-primary {
  background-color: var(--primary);
}

.bg-secondary {
  background-color: var(--secondary);
}

.bg-background {
  background-color: var(--background);
}

.bg-foreground {
  background-color: var(--foreground);
}

.bg-error {
  background-color: var(--error);
}

.bg-success {
  background-color: var(--success);
}

/* border-color */

.b--border {
  border-color: var(--border);
}

.b--primary {
  border-color: var(--primary);
}

.b--error {
  border-color: var(--error);
}

/* utils */

.flex-1 {
  flex: 1 1 0;
}

.min-h-tap {
  min-height: var(--tap-size);
}

.min-w-tap {
  min-width: var(--tap-size);
}

.lh-tap {
  line-height: var(--tap-size);
}

/* remove Arrows/Spinners from input of type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* others */

.focus:focus {
  box-shadow: inset 0 0 0 1px var(--primary);
}

.screen-border--error {
  box-shadow: inset 0 0 0 0.25rem var(--error);
}

@media print {
  .print {
    background-color: white;
    color: black;
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .no-print {
    display: none;
  }
}
